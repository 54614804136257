// Overrides I couldn't do any other way
// Don't add anything here unless there is no choice

.ant-collapse-content-box {
  // Necessary because in v5, the content padding is a constant hard-written in antd
  // (https://github.com/ant-design/ant-design/blob/master/components/collapse/style/index.ts#L301)
  padding: 0 !important;
}

.ant-dropdown-menu {
  // Necessary because v4 used to have this property, but v5 doesn't
  // (https://github.com/ant-design/ant-design/blob/4.x-stable/components/dropdown/style/index.less#L122)
  text-align: left;
}

.ant-modal {
  // Necessary because v4 used to have dividers between header / content / footer, but v5 doesn't
  // thus the spacing is just too compact and needs to be increased (not configurable via tokens)

  &-header {
    margin-bottom: 32px !important;
  }

  &-footer {
      margin-top: 32px !important;
  }
}

.ant-tooltip-inner {
  a {
    color: #00D0A2 !important;
  }
}
