@use 'fonts';
@use "variables";

@mixin for-size($range) {
  $phone-upper-boundary: 768px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-down {
    @media (max-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: variables.$font-family;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums; // Necessary because used to be set in antd v4

  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

button:disabled {
  border: none;
}

.clickable {
  cursor: pointer;
}

.auth0-lock-name {
  font-weight: 500 !important;
  line-height: 1.2 !important;
  @include for-size(phone-only) {
    font-size: 24px !important;
  }
  @include for-size(tablet-portrait-up) {
    font-size: 30px !important;
  }
}

.auth0-lock-header-bg-blur,
.auth0-lock-header-bg,
.auth0-lock-header-bg-solid {
  background: inherit !important;
}

.auth0-lock-widget {
  @include for-size(phone-only) {
    width: 95vw !important;
  }
  @include for-size(tablet-portrait-up) {
    width: 45vw !important;
  }
}

.auth0-lock-view-content {
  @include for-size(tablet-portrait-up) {
    width: 65% !important;
    max-width: 350px;
    margin: auto;
  }
}

.auth0-lock-social-button-text {
  padding: 0 !important;
  text-align: center !important;
  font-weight: normal !important;
}

.auth0-lock-social-button {
  margin-bottom: 20px !important;
  transition: opacity 0.5s ease-out !important;
  border: none !important;
  &:hover {
    opacity: 0.5;
  }
}


#auth0-lock-container .auth0-lock-social-button {
  &:hover .auth0-lock-social-button-icon,
  &:hover .auth0-lock-social-button-text{
    background-color: transparent !important;
  }
}

#auth0-lock-container {
  .auth0-lock.auth0-lock {
    .auth0-lock-social-button.auth0-lock-social-big-button {
      .auth0-lock-social-button-text {
        color: #ffffff !important;
      }
    }
  }
}

.auth0-lock-cred-pane-internal-wrapper {
  max-height: 400px;
}

.auth0-lock.auth0-lock .auth0-lock-submit {
  width: 65% !important;
  height: 40px !important;
  padding: 0 !important;
  margin: 0 auto 20px auto !important;
  max-width: 350px !important;
  border-radius: 3px !important;
}

.auth0-lock.auth0-lock .auth0-lock-error-invalid-hint {
  white-space: normal !important;
}

.auth0-lock.auth0-lock .auth0-lock-center {
  padding: 0 !important
}

@media (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

.expanded-json-raw > td {
  padding: 0 !important;
}
